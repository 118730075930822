.about_content {
    padding-top: 70px;
    padding-bottom: 70px;
    .aboutUs-Img {
        margin-top: 30px;
        margin-bottom: 50px;
        img {
            border: 1px solid #000;
            border-radius: 20px;
            box-shadow: 0px 10px 20px rgba(0,0,0,0.3);
        }
    }
}


@media (max-width: 480px) {
    .about_content {
        padding-top: 40px;
        padding-bottom: 40px;
        .aboutUs-Img {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}