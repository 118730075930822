.why_choose_sec {
    padding-bottom: 50px;
    .choose_item {
        box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
        border-radius: 15px;
        position: relative;
        height: 335px;
        .choose_icon {
            position: absolute;
            top: -20px;
            left: -20px;
        }
        .choose_img {
            border-radius: 15px;
            margin-bottom: 0px;
            height: 240px;
            width: 100%;
            object-fit: cover;
        }
        h4 {
            font-size: 18px;
            line-height: 26px;
            padding: 20px 20px;
        }
    }
}


@media (max-width: 480px) {
    .why_choose_sec {
        .row.mt-5 {
            margin-top: 30px !important;
            .choose_item {
                height: auto;
                margin-bottom: 40px;
                .choose_icon {
                    top: -18px;
                    left: -12px;
                    width: 40px;
                }
                .choose_img {
                    height: auto;
                }
                h4 {
                    font-size: 12px;
                    line-height: 10px;
                    padding: 15px 20px;
                }
            }
        }
    }
}