.aboutSec {
    padding-top: 70px;
    padding-bottom: 70px;
    .aboutUs-Img {
        margin-top: 30px;
        margin-bottom: 30px;
        border: 3px solid #000;
        border-radius: 20px;
        overflow: hidden;
        img {
            // border: 3px solid #000;
            // border-radius: 20px;
            box-shadow: 0px 10px 20px rgba(0,0,0,0.3);
            height: 470px;
            object-fit: cover;
        }
    }
}


@media (max-width: 480px) {
    .aboutSec {
        padding-top: 40px;
        padding-bottom: 30px;
        .aboutUs-Img {
            img {
                height: 240px;
            }
        }
    }
}