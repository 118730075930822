.contact_form {
    background-color: #fff;
    box-shadow: 10px 10px 0px #0c1420;
    padding: 40px 20px;
    border-radius: 20px;
    .form_heading {
        background-color: #0c1420;
        padding: 10px 15px;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 10px;
        h3 {
            color: #fff;
            margin-bottom: 0px;
            font-size: 18px;
    line-height: 22px;
            font-weight: 500;
        }
    }
    form {
        .input_item {
            position: relative;
            margin-bottom: 8px;
            // label {
            //     margin-bottom: 6px;
            //     font-size: 13px;
            //     line-height: 16px;
            // }
            input {
                padding: 9px 10px 9px 10px;
                height: auto;
                font-size: 14px;
                border: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                border-radius: 5px;
                &:focus, &:focus-visible {
                    box-shadow: none;
                }
            }
            select {
                padding: 9px 10px 9px 10px;
                height: auto;
                font-size: 14px;
                border: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                border-radius: 5px;
                &:focus {
                    box-shadow: none;
                }
            }
            textarea {
                padding: 9px 10px 9px 10px;
                height: auto;
                font-size: 14px;
                border: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                border-radius: 5px;
                &:focus, &:focus-visible {
                    box-shadow: none;
                }
            }
        }
    }
}


@media (max-width: 480px) {
    .contact_form {
        box-shadow: 5px 5px 0px #0c1420;
        padding: 20px 20px;
        .form_heading {
            h3 {
                font-size: 12px;
                line-height: 14px;
            }
        }
        form {
            .input_item {
                select {
                    font-size: 11px;
                }
                input {
                    font-size: 11px;
                }
                textarea {
                    font-size: 11px;
                }
            }
        }
    }
}