.CustomSolutions_sec {
    background-color: #F4F4F4;
    padding-top: 70px;
    padding-bottom: 70px;
}


@media (max-width: 480px) {
    .CustomSolutions_sec {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}