.main_banner {
  padding-top: 80px;
  padding-bottom: 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .row {
    position: relative;
    .banner_content {
      padding-right: 100px;
      border-left: 4px solid #ffa500;
      h5 {
        font-size: 40px;
        line-height: 55px;
        text-transform: capitalize;
        font-weight: 500;
        padding-left: 20px;
        color: #fff;
      }
      h1 {
        font-size: 40px;
        line-height: 55px;
        text-transform: capitalize;
        font-weight: 700;
        padding-left: 20px;
        color: #fff;
      }
    }
  }
}

@media (max-width: 480px) {
  .main_banner {
    padding-top: 50px;
    padding-bottom: 50px;
    .row {
      .banner_content {
        h5 {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
