.testimonials_sec {
    padding-top: 50px;
    padding-bottom: 100px;
    .testi_item {
        // background-color: #0C1420;
        border-radius: 20px;
        padding: 10px 15px;
        margin: 10px 0px;
        // min-height: 240px;
        .comment {
            h5 {
                // color: #fff;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 15px;
            }
            p {
                // color: #fff;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .testi-foot {
            position: relative;
            margin-top: 23px;
            display: flex;
            align-items: center;
            // padding-left: 60px;
            img {
                // position: absolute;
                // left: 0;
                // top: -6px;
                width: 80px;
                // height: 50px;
                // object-fit: cover;
                // border-radius: 50%;
                margin-bottom: 10px;
                margin-right: 20px;
            }
            h6 {
                // color: #fff;
                font-size: 16px;
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 0px;
                // color: #fff;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}


@media (max-width: 480px) {
    .testimonials_sec {
        padding-top: 30px;
        padding-bottom: 70px;
        .testi_item {
            padding: 0px 15px;
            margin: 0px 0px;
            .comment {
                p {
                    font-size: 11px;
                    line-height: 16px;
                }
            }
            .testi-foot {
                h6 {
                    font-size: 11px;
                    margin-bottom: 5px;
                }
                p {
                    margin-bottom: 0px;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
}