.history_sec {
    padding-top: 80px;
    padding-bottom: 40px;
    img {
        width: 380px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    p {
        font-size: 22px;
        line-height: 34px;
    }
}

@media (max-width: 480px) {
    .history_sec {
        padding-top: 50px;
        padding-bottom: 30px;
        img {
            width: 200px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        p {
            font-size: 12px;
            line-height: 20px;
        }
    }
}