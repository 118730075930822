.cta_section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding-top: 40px;
    padding-bottom: 40px;
    h4 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 0px;
    }
    .site_btn {
        i {
            margin-right: 5px;
        }
    }
}

@media (max-width: 480px) {
    .cta_section {
        padding-top: 20px;
        padding-bottom: 20px;
        h4 {
            font-size: 11px;
            line-height: 12px;
            margin-bottom: 0px;
        }
        .site_btn {
            padding: 7px 11px 4px;
            i {
                margin-right: 2px;
            }
        }
    }
}