.about_banner {
    padding-top: 140px;
    padding-bottom: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 620px;
}

@media (max-width: 480px) {
    .about_banner {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 200px;
    }
}