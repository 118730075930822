.main_header {
    background-color: #0C1420;
    .navbar {
        padding: 20px 0px;
        .navbar-brand {
            img {
                width: 200px;
            }
        }
        .navbar-nav {
            padding-left: 0px;
            .nav-item {
                a {
                    color: #fff;
                    padding: 10px 23px;
                    font-size: 14px;
                }
            }
        }
    }
    
    .header_btn {
        .border_btn {
            margin-right: 6px;
            border-color: #fff;
            color: #fff;
        }
    }
}


@media (max-width: 480px) {
    .main_header {
        .navbar {
            padding: 10px 0px;
            .navbar-brand {
                img {
                    width: 180px;
                }
            }
            .navbar-toggler {
                color: #fff;
                outline: none;
                font-size: 22px;
                padding: 0px 0px;
                box-shadow: none;
            }

            .navbar-collapse {
                position: absolute;
                top: 59px;
                background-color: #0c1420;
                width: 100%;
                left: 0;
                right: 0;
                padding-bottom: 20px;
            }

            .header_btn {
                padding: 0px 25px;
            }
        }
    }
}