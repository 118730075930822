.counter_item {
    .counter, .counter_sign {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
    }
    h5 {
        color: #fff;
        font-size: 14px;
        line-height: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
}


@media (max-width: 480px) {
    .counter_item {
        h5 {
            font-size: 10px;
            line-height: 12px;
            margin-top: 4px;
        }
        .counter, .counter_sign {
            font-size: 18px;
            line-height: 20px;
        }
    }
}