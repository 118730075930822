.main_footer {
    padding-top: 120px;
    padding-bottom: 50px;
    position: relative;
    background-color: #fff;
    margin-top: 50px;
    // height: 600px;
    overflow: hidden;
    &::before {
        content: "";
        width: 200%;
        height: 0;
        padding-top: 70%;
        border-radius: 100%;
        background: #0C1420;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
    }
    .container-fluid {
        position: relative;
        width: 90%;
    }
    p, li, a {
        color: #fff;
        font-size: 13px;
    }
    .top_footer {
        .footer_title {
            color: #fff;
            font-size: 19px;
            font-weight: 500;
            line-height: 25px;
            text-transform: capitalize;
            margin-bottom: 20px;
        }
        .footer_about {
            img {
                width: 200px;
                margin-bottom: 20px;
            }
        }
        .footer_nav {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        .footer_contact {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                margin-bottom: 20px;
                padding-left: 30px;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    font-size: 15px;
                }
            }
        }
    }
    .bottom_footer {
        padding-top: 30px;
        margin-top: 50px;
        border-top: 1px solid rgba(255,255,255,0.2);
        .footer_sm {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            li {
                margin-right: 8px;
                i {
                    border: 1px solid #fff;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
        .copyright_text {
            margin-bottom: 0;
            text-align: center;
        }
        .bottom_links {
            padding: 0;
            list-style: none;
            margin: 0;
            display: flex;
            align-items: center;
            li {
                margin-left: 40px;
            }
        }
    }
}


@media (max-width: 480px) {
    .main_footer{
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 0;
        &::before {
            content: "";
            width: 100%;
            padding-top: 0;
            border-radius: 0;
            background: #0C1420;
            position: absolute;
            top: 0%;
            left: 0;
            transform: translateX(0%);
            height: 100%;
        }
        & {
            p, li, a {
                color: #fff;
                font-size: 11px;
                line-height: 16px;
            }
        }
        .top_footer {
            .footer_title {
                font-size: 14px;
                line-height: 15px;
                margin-bottom: 10px;
                margin-top: 30px;
            }
            .footer_nav {
                li {
                    margin-bottom: 7px;
                    i {
                        font-size: 10px;
                    }
                }
            }
            .footer_contact {
                li {
                    margin-bottom: 10px;
                    padding-left: 20px;
                    position: relative;
                }
            }
        }
        .bottom_footer {
            .footer_sm {
                margin: 0px 0px 20px;
                justify-content: center;
            }
            .policy_links {
                justify-content: center !important;
            }
            .bottom_links {
                li {
                    margin-left: 0;
                }
            }
        }
    }
}