.exclusive_features_sec {
    padding-top: 70px;
    padding-bottom: 60px;
    background-color: #0C1420;
    .feature_item {
        padding: 30px 45px;
        img {
            width: 42px;
            min-height: 42px;
            object-fit: contain;
            margin-bottom: 15px;
        }
        h4 {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0px;
        }
    }
}


@media (max-width: 480px) {
    .exclusive_features_sec {
        padding-top: 40px;
        padding-bottom: 30px;
        .feature_item {
            padding: 20px 10px;
            img {
                width: 33px;
                min-height: 33px;
            }
            h4 {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
}