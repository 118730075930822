.special_features_sec {
    padding-top: 50px;
    padding-bottom: 50px;
    .feature_item {
        margin-bottom: 30px;
        margin-top: 30px;
        img {
            height: 240px;
            object-fit: cover;
            margin-bottom: 10px;
            border-radius: 15px;
        }
        h4 {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }
}


@media (max-width: 480px) {
    .special_features_sec {
        padding-top: 40px;
        padding-bottom: 20px;
        .feature_item {
            margin-bottom: 10px;
            margin-top: 10px;
            
            img {
                height: 120px;
                border-radius: 10px;
                width: 100%;
            }
            h4 {
                font-size: 11px;
                line-height: 12px;
            }
        }
    }
}