.customSolution-item {
    .img_box {
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        img {
            position: relative;
            height: 360px;
            object-fit: cover;
            border: 1px solid #0c1420;
            border-radius: 10px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 50%;
            background-image: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,1));;
            left: 0;
            right: 0;
        }
        h4 {
            position: absolute;
            z-index: 1;
            bottom: 20px;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 0px;
        }
    }
    p {
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0px;
    }
}

.testimonials_slider {
    .slick-slider {
        .slick-slide {
            padding: 20px 15px 0px;
        }
        .slick-arrow {
            border: 1px solid #000;
            font-size: 16px;
            height: 40px;
            width: 40px;
        }
        .slick-arrow:before {
            color: #071c1f;
            font-size: 25px;
            opacity: 1;
        }
        .slick-prev {
            left: -55px;
        }
        .slick-prev:before {
            // content: "\f104";
            font-family: FontAwesome;
            font-size: 24px;
        }
        .slick-next {
            right: -55px;
        }
        .slick-next:before {
            // content: "\f105";
            font-family: FontAwesome;
            font-size: 24px;
        }
    }

}


@media (max-width: 480px) {
    .customSolution-item {
        .img_box {
            img {
                height: 220px;
                width: 100%;
            }
            h4 {
                font-size: 11px;
                line-height: 12px;
            }
        }
    }

    .testimonials_slider {
        .slick-slider {
            .slick-slide {
                padding: 10px 10px 0px;
            }
            
        }
    
    }
}