.service_tabs_sec {
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        background-color: rgba(255, 255, 255, 0.4);
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 0;
    }
    .tabs_list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        z-index: 9;
        position: relative;
        li {
            display: inline-block;
            margin: 12px 10px;
            .site_btn {
                padding: 11px 35px;
                font-size: 16px;
            }
        }
    }
}

.service_section {
    .service_text {
        padding: 100px 200px;
        p {
            font-size: 20px;
            line-height: 32px;
            margin-top: 20px;
        }
    }
    .container-fluid {
        padding: 0;
    }
}



@media (max-width: 480px) {
    .service_tabs_sec {
        padding-top: 20px;
        padding-bottom: 20px;
        .tabs_list {
            li {
                margin: 4px 2px;
                .site_btn {
                    padding: 4px 13px;
                    font-size: 10px;
                }
            }
        }
    }

    .offerings_heading {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .service_section {
        .service_text {
            padding: 40px 0px 20px;
            p {
                font-size: 11px;
                line-height: 18px;
            }
        }
    }
}