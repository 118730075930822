.policy_content {
    padding-top: 50px;
    padding-bottom: 50px;
    h1 {
        font-size: 35px;
        line-height: 45px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h3 {
        font-size: 22px;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 10px;
    }
}